function cookie(key, value, expire) {
	var cookieObj = {},
		cookieArr;

	cookieArr = document.cookie.split('; ');

	for (let i = 0; i < cookieArr.length; i++) {
		let cookie = cookieArr[i],
			keyVal = cookie.split('='),
			val;

		val = keyVal[1];

		if (val) {
			while (val.match(/%[0-9a-f]{2}/i) !== null) {
				try {
					let decodedVal = decodeURIComponent(val);
					val = decodedVal;
				} catch (e) {
					//do nothing
				}
			}

			try {
				let parsedVal = JSON.parse(val);
				val = parsedVal;
			} catch (e) {
				//do nothing
			}

			cookieObj[keyVal[0]] = val;
		}
	}

	if (typeof key === 'undefined') {
		return cookieObj;
	}

	if (typeof key !== 'string') {
		return false;
	}

	if (typeof value !== 'undefined') {
		if (value === null) {

			document.cookie = key + '=;domain=.' + window.location.hostname + ';path=/;max-age=0';

			return true;

		} else if (typeof value !== 'string') {
			try {
				value = JSON.stringify(value);
			} catch (e) {
				return false;
			}
		}

		document.cookie = key + '=' + encodeURIComponent(value) + ';domain=.' + window.location.hostname + ';path=/' + (!Number.isNaN(expire) ? ';max-age=' + expire : '');

		return true;
	}

	if (typeof cookieObj[key] !== 'undefined') {
		return cookieObj[key];
	} else {
		return false;
	}
}

function cookieExists(key) {
	var cookieObj = cookie();

	return typeof cookieObj[key] !== 'undefined';
}



// console.log('cookie():', cookie());

// console.log("set 'test' to 'some_string':", cookie('test', 'some_string'));

// console.log("cookie('test'):", cookie('test'));

// console.log("set 'test' to an object:", cookie('test', {foo: 'bar', baz: 'test'}));

// console.log("cookie('test'):", cookie('test'));

// console.log("delete 'test':", cookie('test', null));

// console.log("cookie('test'):", cookie('test'));

export {
	cookie,
	cookieExists
}
