import { cookie, cookieExists } from './cookie.js';

var banner,
	scripts,
	gtag     = window.gtag || function () { window.dataLayer = window.dataLayer || []; dataLayer.push(arguments); };

function showBanner() {
	banner.classList.add('show');
}

function hideBanner() {
	banner.classList.remove('show');
}

function onDOMReady(callback) {

	//add callback first
	document.addEventListener('DOMContentLoaded', callback);

	//if the document is already ready, the above will never fire, so call the callback directly
	if (document.readyState === 'interactive' || document.readyState === 'complete') {
		callback();
	}
}

function initCookieManager(config) {

	var consent,
		defaultConfig = {
			prefix: 'shi-',
			cookie_categories: {
				necessary: {
					accept: true,
					cookies: [
						/wordpress_sec_[a-zA-Z0-9]*/,
						/wordpress_logged_in_[a-zA-Z0-9]*/,
						/wp-settings-\d+/,
						/wp-settings-time-\d+/,
						'wordpress_test_cookie'
					],
					gtm_types: [
						'functionality_storage',
						'security_storage',
						'personalization_storage'
					]
				},
				analytics: {
					accept: false,
					cookies: [
						'_gcl_au',
						'_ga',
						'_gid',
						/gat_UA-\d+-\d+/
					],
					gtm_types: [
						'analytics_storage'
					]
				},
				advertising: {
					accept: false,
					cookies: [
						'_fbp'
					],
					gtm_types: [
						'ad_storage'
					]
				}
			}
		};

	config = Object.assign({}, defaultConfig, config);
	if (!config.cookie_categories.necessary) {
		config.cookie_categories.necessary = {accept: true, cookies: []};
	}
	config.cookie_categories.necessary.cookies.push(config.prefix + 'cookie-consent');

	consent = cookie(config.prefix + 'cookie-consent')

	//banner = (new DOMParser()).parseFromString(template, "text/html");

	//document.body.appendChild(banner);

	function rejectAll() {
		let categories = Object.keys(config.cookie_categories);
		for (let i = 0; i < categories.length; i++) {
			let category = categories[i];

			if (category !== 'necessary') {
				consent.categories[category] = false;
			}
		}
	}

	function acceptAll() {
		let categories = Object.keys(config.cookie_categories);
		for (let i = 0; i < categories.length; i++) {
			let category = categories[i];

			consent.categories[category] = true;
		}
	}

	onDOMReady(function () {

		banner  = document.querySelector('.cookie-banner');
		scripts = document.querySelectorAll('script[type="opt-in"][data-category]');

		document.body.addEventListener('click', function (e) {

			if (e.target.matches('.cookie-banner .cc-btn-reject') || e.target.matches('.cookie-banner .cc-btn-accept')) {

				consent.confirmed = true;

				if (e.target.matches('.cookie-banner .cc-btn-reject')) {
					rejectAll();
				} else if (e.target.matches('.cookie-banner .cc-btn-accept')) {
					acceptAll();
				}

				//update GTM
				gtag('consent', 'update', getGTMConsents());

				cookie(config.prefix + 'cookie-consent', consent, 60*60*24*30*6); //save for 6 months

				hideBanner();
			}
		});

		document.body.addEventListener('click', function (e) {
			if (e.target.matches('[data-js="manage-cookie-settings"]')) { 
				showBanner();
			}
		});
	});

	//ensure consent is a properly-formated object
	if (
		typeof consent !== 'object' ||
		consent == null ||
		!consent.hasOwnProperty('confirmed') ||
		typeof consent.confirmed !== 'boolean' ||
		!consent.hasOwnProperty('categories') ||
		typeof consent.categories !== 'object' ||
		consent.categories == null
	) {

		//create default consent object
		consent = {
			confirmed: false,
			categories: {}
		};
	}

	//if previously confirmed (non-default), check for and load any new categories into consent
	if (consent.confirmed) {
		let categories = Object.keys(config.cookie_categories);

		for (let i = 0; i < categories.length; i++) {
			let category = categories[i];

			if (consent.categories[category] == undefined) {
				consent.categories[category] = config.cookie_categories[category].accept;
				consent.confirmed = false; //re-prompt the user
			}
		}
	}

	//check again because we might have reset it above
	if (!consent.confirmed) {

		//if no categories in consent (default object)
		if (Object.keys(consent.categories).length == 0) {
			//accept all by default
			acceptAll();
		}

		//prompt user
		onDOMReady(showBanner);
	}

	function getGTMConsents() {
		let gTMconsents = {};
		let categories = Object.keys(consent.categories);
		for (let i = 0; i < categories.length; i++) {
			let category = categories[i];

			if (config.cookie_categories[category] !== undefined) {
				for (let j = 0; j < config.cookie_categories[category].gtm_types.length; j++) {
					let type = config.cookie_categories[category].gtm_types[j];

					if (consent.categories[category] !== undefined) {
						gTMconsents[type] = consent.categories[category] ? 'granted' : 'denied';
					} else {
						gTMconsents[type] = config.cookie_categories[category].accept ? 'granted' : 'denied';
					}
				}
			}
		}

		return gTMconsents;
	}

	function getAllowedCookies() {
		let allowedCookies = [];
		let categories = Object.keys(consent.categories);
		for (let i = 0; i < categories.length; i++) {
			let category = categories[i];

			if (
				(consent.categories[category] !== undefined && consent.categories[category]) ||
				(config.cookie_categories[category] !== undefined && config.cookie_categories[category].accept)
			) {
				allowedCookies = allowedCookies.concat(config.cookie_categories[category].cookies);
			}
		}

		return allowedCookies;
	}

	//default GTM settings from cookie
	gtag('consent', 'default', getGTMConsents());

	//enable any allowed script categories
	onDOMReady(function () {

		scripts.forEach(function (script) {

			if (consent.categories[script.dataset.category]) {

				let newScript = document.createElement('script');

				newScript.innerText = script.innerText;

				if (script.dataset.type) {
					newScript.setAttribute('type', script.dataset.type);
				} else {
					newScript.setAttribute('type', 'application/javascript');
				}

				if (script.dataset.src) {
					newScript.setAttribute('src', script.dataset.src);
				}

				script.parentElement.insertBefore(newScript, script);
				script.parentElement.removeChild(script);
			}
		});
	});

	//delete any disallowed cookies that might have been set
	var cookies = cookie();
	var cookieNames = Object.keys(cookies);
	var allowedCookies = getAllowedCookies();
	for (let i = 0; i < cookieNames.length; i++) {
		let cookieName = cookieNames[i];
		let found = false;

		for (let j = 0; j < allowedCookies.length; j++) {
			let allowedCookie = allowedCookies[j];

			if (allowedCookie instanceof RegExp) {
				if (cookieName.match(allowedCookie)) {
					found = true;
				}
			} else if (cookieName === allowedCookie) {
				found = true;
			}
		}

		if (!found) {
			cookie(cookieName, null); //delete
		}
	}

	if (window.location.search.match(/\bmanage-cookie-settings\b/) !== null) {
		onDOMReady(showBanner);
	}
}

export { initCookieManager };