function initCookieBannerUI() {
	// Cookie Banner UI JS
	function setCookieSettingsContentHeight() {
		document.querySelectorAll('.cookie-banner').forEach(function(banner) {
			var settingsNav     = banner.querySelector('.cc-settings-nav'),
				settingsSave    = banner.querySelector('.cc-settings-save'),
				navHeight       = settingsNav.offsetHeight + settingsSave.offsetHeight,
				settingsContent = banner.querySelector('.cc-settings-tabbed-content');

			settingsContent.style.height = navHeight + 'px';

			console.log(navHeight);
		});
	}

	document.querySelectorAll('.cookie-banner').forEach(function (banner) {
		var open = banner.querySelector('button#openSettings'),
			save = banner.querySelector('button#saveCookieSettings');

		if (open) {
			open.addEventListener('click', function (event) {
				event.preventDefault();

				banner.querySelector('.cc-settings-wrapper').style.display = 'flex';

				setCookieSettingsContentHeight();
			});
		}

		if (save) {
			save.addEventListener('click', function (event) {
				event.preventDefault();

				banner.querySelector('.cc-settings-wrapper').style.display = 'none';
			});
		}
	});

	window.addEventListener('resize', setCookieSettingsContentHeight);
}

export { initCookieBannerUI };