import { initCookieBanner } from './cookie-banner/cookie-banner.js';

var cookie_config = {
    prefix: 'ocb-',
    cookie_categories: {
        necessary: {
            accept: true,
            cookies: [
                /wordpress_sec_[a-zA-Z0-9]*/,
                /wordpress_logged_in_[a-zA-Z0-9]*/,
                /wp-settings-\d+/,
                /wp-settings-time-\d+/,
                'wordpress_test_cookie',
                'ocb'
            ],
            gtm_types: [
                'functionality_storage',
                'security_storage',
                'personalization_storage'
            ]
        },
        analytics: {
            accept: false,
            cookies: [
                '_gcl_au',
                '_ga',
                '_gid',
                /gat_UA-\d+-\d+/
            ],
            gtm_types: [
                'analytics_storage'
            ]
        },
        advertising: {
            accept: false,
            cookies: [
                '_fbp'
            ],
            gtm_types: [
                'ad_storage'
            ]
        }
    }
};
initCookieBanner(cookie_config);